@import url(https://fonts.googleapis.com/css?family=Cairo);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.over 
 {
  z-index: 30 ;
  position: absolute;
  bottom:20px;
  display: block;
   margin: 0 auto;
  width: 100%;
  align-items: 'center',
 }
