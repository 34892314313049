@import url('https://fonts.googleapis.com/css?family=Cairo');

.App {
  text-align: center;
}

.over 
 {
  z-index: 30 ;
  position: absolute;
  bottom:20px;
  display: block;
   margin: 0 auto;
  width: 100%;
  align-items: 'center',
 }